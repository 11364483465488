import {
  BookAppointmentRequest,
  BookAppointmentResponse,
  IClientAppointmentProto, IProviderProto,
  IProviderServiceProto
} from "../../../../compiled";
import {useLocation} from "react-router-dom";
import {SendRpc} from "../../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import React, {useState, useEffect} from "react";
import {useProfile} from "../../../../UserProfileProvider";
import {DateDiv} from "./DateDiv";
import '../../../App.css'

// This gets passed along in the router
type Props = {
  provider: IProviderProto,
  service: IProviderServiceProto
  appointmentTime: number,
  onCancel: () => void,
}

export const ConfirmBooking = (props: Props) => {

  const {getIdTokenClaims} = useAuth0();
  const {setProfile} = useProfile();

  const [bookingResponse, setBookingResponse] = useState<IClientAppointmentProto>();
  const [bookingError, setBookingError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!bookingResponse) {
      bookAppointment();
    }
  }, []);

  const bookAppointment = () => {

    setBookingError('')

    let request = BookAppointmentRequest.encode(
        new BookAppointmentRequest({
          appointmentTime: props.appointmentTime,
          serviceId: props.service.sku,
          providerId: props.provider.providerId
        })
    ).finish()

    SendRpc(getIdTokenClaims, 'book_appointment', request)
        .then(r => {
          let response = BookAppointmentResponse.decode(r);
          console.log('response', JSON.stringify(response.toJSON()));
          if (response.okay && response.appointment) {
            setBookingResponse(response.appointment);
            if (response.updatedProfile) {
              setProfile(response.updatedProfile);
            }
          } else {
            setBookingError('Error booking. ' + response.error)
          }
        })
        .catch(e => {
          setBookingError('Error booking. ' + e);
        });
  }


  if (bookingError) {
    return (<div style={{
      flex: 1, display: 'flex',
      alignItems: 'center', justifyContent: 'center', color: 'red'
    }}>
      {bookingError}
    </div>);
  }


  if (!bookingResponse) {
    return <div>
      Loading...
    </div>
  }
  
  var appointmentDate = new Date(props.appointmentTime);

  return (<>
        <div className={'BookingOverlayTopRow'}>
          <div className='BookingOverlaySectionMargin'>
            <div className={'HorizontalCenteredFlex'}>
              <div style={{flex: 1}}>Appointment Confirmed 🎉</div>
              <img className={'BookingOverlayClose'} onClick={props.onCancel}
                   src={require('../../../../images/icons/close.png')}/>
            </div>
          </div>
        </div>
        <div className={'BookingOverlayBottomRow'}>
          <div className='BookingOverlaySectionMargin'>
            <div className={'ConfirmationBottomSection'}>
             
              <div className={'ConfirmationRow'}>
                <div className={'ConfirmationRowLeftIcon'}>
                  <img className={'ConfirmationRowLeftImage'} 
                       src={require('../../../../images/provider/booking/30.png')}/>
                </div>
                <div className={'ConfirmationRowRight'}>
                  <div><b>{appointmentDate.toLocaleDateString(undefined,
                      {weekday: 'long', month: 'short', day: 'numeric'})}
                    &nbsp;at&nbsp;
                    {appointmentDate.toLocaleTimeString(undefined,
                        {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'}
                    )}
                  </b></div>
                  <div>{props.service.name} ({props.service.lengthMinutes} min)</div>
                </div>
              </div>

              <div className={'ConfirmationRow'}>
                <div className={'ConfirmationRowLeftIcon'}>
                  <img className={'ConfirmationRowLeftImage'} 
                       src={require('../../../../images/provider/booking/31.png')}/>
                </div>
                <div className={'ConfirmationRowRight'}>
                  <div>{props.provider.address}</div>
                </div>
              </div>

              <div className={'ConfirmationRow'}>
                <div className={'ConfirmationRowLeftIcon'}>
                  <img className={'ConfirmationRowLeftImage'} 
                       src={require('../../../../images/provider/booking/32.png')}/>
                </div>
                <div className={'ConfirmationRowRight'}>
                  <div>{props.service.lengthMinutes} min · ${props.service.price}</div>
                </div>
              </div>

              <div className={'ConfirmationButton ConfirmationButtonBlack'}>Add to Calendar</div>
              <div className={'ConfirmationButton'}>Book Another Appointment</div>
              <div className={'CancelButton'}>Cancel appointment</div>
            </div>
          </div>
        </div>
      </>
  );
}