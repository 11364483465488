import {useProfile} from "../../UserProfileProvider";
import {SendRpc} from "../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {DeleteProfileRequest, DeleteProfileResponse, UserProfileProto} from "../../compiled";
import {FormEvent, FormEventHandler, useState} from "react";
import {useNavigate} from "react-router-dom";
import '../App.css';


export const UserProfile = () => {

  const {getIdTokenClaims, logout} = useAuth0();
  const {profile, setProfile} = useProfile();
  const [deleteRpcError, setDeleteRpcError] = useState('');
  const navigate = useNavigate();

  const auth0Logout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  const deleteProfile = () => {
    SendRpc(getIdTokenClaims, "delete_user_profile",
        DeleteProfileRequest.encode(new DeleteProfileRequest({
          profileId: profile?.profileId
        })).finish())
        .then(bytes => {
          let response = DeleteProfileResponse.decode(bytes);
          if (response.okay) {
            setProfile(null);
            navigate('/');
          } else {
            setDeleteRpcError('Error deleting your profile');
          }
        })
        .catch(e => {
          setDeleteRpcError('Error deleting your profile. ' + e);
        });
  }

  return <div className={'AppSection BeigeSection'}>
    <div className={'AppSectionContent'} style={{margin: 20}}>
      <h1>My profile</h1>
      <br/>
      <b>Appointment history</b>
      {profile?.appointments?.map(appt => <div>
            {appt.serviceName} with {appt.providerName}
            <br/>
            {new Date(appt.startTimeMs as number).toLocaleString()}
            <br/><br/>

          </div>
      )}

      <div style={{textAlign: 'left', color: "lightgray"}}>
    <pre>
    {JSON.stringify(profile, null, 2)}
    </pre>
      </div>

      <button onClick={auth0Logout}>log out</button>
      <br/><br/>
      <button onClick={deleteProfile}>delete my profile</button>
      {deleteRpcError && <div style={{color: 'red'}}>{deleteRpcError}</div>}
    </div>
  </div>;
}