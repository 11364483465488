import React, {useEffect, useRef, useState} from 'react';
import {SendRpc} from "../../../rpcSender";
import {FindProviderRequest, FindProviderResponse, IProviderProto,} from "../../../compiled";
import {useApiIsLoaded, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import './MapSearch.css'

interface Props {

  // These are the initial values of the map bounds.
  initialNorth: number,
  initialEast: number,
  initialSouth: number,
  initialWest: number,
}

/**
 * @constructor
 */
export const ProviderSearchResultsList = (props: Props) => {

  const {getIdTokenClaims} = useAuth0();

  const [providers, setProviders] = useState<Map<string, IProviderProto>>(
      new Map<string, IProviderProto>());
  
  const [rpcLoading, setRpcLoading] = useState(true);
  const [rpcError, setRpcError] = useState('');

  // These CAN change as a result of the map moving, and will intentionally cause a
  // re-render of this component. 
  const [north, setNorth] = useState(props.initialNorth);
  const [east, setEast] = useState(props.initialEast);
  const [south, setSouth] = useState(props.initialSouth);
  const [west, setWest] = useState(props.initialWest);

  const map = useMap()

  const apiLoaded = useApiIsLoaded();
  const markerLibrary = useMapsLibrary('marker');

  const infoWindow = useRef(new google.maps.InfoWindow({
    content: 'hmmm',
    ariaLabel: "hummmm",
    minWidth: 300,
    headerContent: 'yo'
  }));

  // All POI markers currently out on the map. Since these are map elements so they are
  // persisted across renders. This is a dictionary so we can do a fast lookup by id
  // and keep any markers that already exist. This eliminates what would otherwise be
  // a discernible flicker in the markers.
  const markers = useRef<Map<string, google.maps.marker.AdvancedMarkerElement>>(
      new Map<string, google.maps.marker.AdvancedMarkerElement>());

  // The RPC is not fired until the marker library is loaded, or else it's not possible
  // to render them when they return 
  useEffect(() => {
    if (map && markerLibrary) {
      updateSearchResults();
    }
  }, [map, markerLibrary, north, south, west, east])

  useEffect(() => {
    if (map) {
      map.addListener('dragend', onViewportChange)
      map.addListener('zoom_changed', onViewportChange)
    }
  }, [map])

  const updateSearchResults = () => {

    setRpcLoading(true);
    setRpcError('');

    let request = FindProviderRequest.encode(new FindProviderRequest({
      north: north,
      east: east,
      south: south,
      west: west,
    })).finish();

    SendRpc(getIdTokenClaims, "find_provider", request)
        .then(r => {
          let response = FindProviderResponse.decode(r);

          if (response.viewportTooLarge) {
            setRpcError('Map viewport is too large - try zooming in!')
          }

          let updatedProviders: Map<string, IProviderProto> = new Map<string, IProviderProto>();
          response.providers.forEach(provider => {
            if (provider.providerId) {
              updatedProviders.set(provider.providerId, provider);
            }
          });
          
          setProviders(updatedProviders);

          // Clear any markers no longer in the search results.
          markers.current.forEach((marker, id, googleMap) => {
            if (!updatedProviders.has(id)) {
              console.log('removing marker', marker.title);
              marker.map = null;
              markers.current.delete(id); // Supposedly deleting while iterating is ok...
            }
          });
          
          // Add markers for ones still there.
          updatedProviders?.forEach((provider, id, googleMap) => {
            if (!markers.current.has(id)) {

              if (!provider.lat || !provider.lng || !provider.providerId) {
                // Shouldn't happen but this check makes typescript happy.
                return;
              }

                // Not existent, make a new one.
              let marker = new google.maps.marker.AdvancedMarkerElement({
                position: {lat: provider.lat, lng: provider.lng},
                title: provider.name,
                map: map,
                gmpClickable: true,
                content: new google.maps.marker.PinElement({
                  background: '#96695e',
                  glyphColor: 'white',
                  borderColor: 'black',
                }).element
              });

              // Add a click listener for each marker, and set up the info window.
              marker.addListener('click', () => {
                infoWindow.current.close();
                infoWindow.current.setHeaderContent(provider.name);
                infoWindow.current.setContent(provider.address);
                infoWindow.current.open(marker.map, marker);
              });

              markers.current.set(provider.providerId, marker);
            }
          })

        })
        .catch(e => {
          console.log(e)
          setRpcError('Error searching, so sorry!')
        })
        .finally(() => {
          setRpcLoading(false);
        })
  };

  /**
   * Called when the user changes viewport on the map. Typically called
   * on a drag end or a zoom end so it doesn't change too often.
   */
  const onViewportChange = () => {
    let bounds = map?.getBounds();
    if (bounds) {

      // Setting the bounds automatically triggers a new request because
      // of the useEffect on this thing.
      setNorth(bounds.getNorthEast().lat());
      setEast(bounds.getNorthEast().lng());
      setSouth(bounds.getSouthWest().lat());
      setWest(bounds.getSouthWest().lng());
    }
  }

  if (!apiLoaded) {
    return <div>Loading maps api...</div>
  }

  if (!markerLibrary) {
    return <div>Loading marker library...</div>
  }

  if (rpcError) {
    return (<div style={{color: 'red'}}>{rpcError}</div>);
  }

  if (providers.size == 0) {
    if (rpcLoading) {
      return <div>Loading...</div>
    } else {
      return <div>No providers found, so sorry!</div>
    }
  }

  /**
   *
   {props.pois?.map((poi: Poi) => (
        <AdvancedMarker key={poi.key} position={poi.location}>
          <Pin background={'gray'} glyphColor={'#fff'} borderColor={'black'}/>
        </AdvancedMarker>
    ))}
   */


  // The left side of the search results. Interacts directly with the map so it doesn't
  // cause a re-render.
  return <>
    {Array.from(providers.values()).map(provider => {
      return <Link key={provider.providerId} to={`/p/${provider.shortUrl}`}>
        <div className={'MapSearchResult'}>
          <div><b>{provider.name}</b></div>
          <div>{provider.address}</div>
        </div>
      </Link>
    })}

    {rpcLoading && <div style={{color: 'gray'}}>refreshing results...</div>}
  </>;
}

export default ProviderSearchResultsList;
