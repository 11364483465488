/* global google */

import React, {useLayoutEffect} from 'react';
import {useLocation, useSearchParams} from "react-router-dom";
import {ProviderSearchResultsMap} from "./ProviderSearchResultsMap";
import ProviderSearchResultsList from "./ProviderSearchResultsList";
import {useApiIsLoaded} from "@vis.gl/react-google-maps";

/**
 * This contains the search results list on the left side and the map on the right side.
 *
 * The map is in a separate component so it never re-renders. Re-rendering is a jarring and
 * expensive operation. This is accomplished by never using state which would cause a rerender
 * in either this or the child map component.
 *
 * The list component below this IS allowed to re-render. When it has new data for the map,
 * it interacts through the javascript api rather than editing the react hierarchy.
 */
export const ProviderSearchResults = () => {

  const [searchParams] = useSearchParams();

  // Initial values -- these are used to create the google map in the react app
  // so it never has to re-render.
  const initialNorth = parseFloat(searchParams.get('n') || '0.0')
  const initialEast = parseFloat(searchParams.get('e') || '0.0')
  const initialSouth = parseFloat(searchParams.get('s') || '0.0')
  const initialWest = parseFloat(searchParams.get('w') || '0.0')

  const apiIsLoaded = useApiIsLoaded();

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Trigger on route change

  if (!apiIsLoaded) {
    return <>Loading maps api...</>
  }

  return (
      <div className={'AppSection'}>
        <div className={'AppSectionContent Flex1'}>

          <div style={{display: 'flex', flexDirection: 'row', flex: 1, height: 500,
            marginLeft: '10vw', marginRight: '10vw'}}>

            {/*left side list*/}
            <div style={{display: 'flex', flexDirection: 'column', flex: 1,
              alignItems: 'start', overflowY: 'scroll'}}>
              <ProviderSearchResultsList initialEast={initialEast} initialNorth={initialNorth}
                                         initialSouth={initialSouth}
                                         initialWest={initialWest}/>

            </div>

            {/*right side map view*/}
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: 420}}>
              <ProviderSearchResultsMap defaultBounds={new google.maps.LatLngBounds(
                  {west: initialWest, east: initialEast, north: initialNorth, south: initialSouth})}/>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ProviderSearchResults;
