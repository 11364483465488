/* global google */

import React, {Component, ReactElement} from 'react';
import './App.css';
import {Route} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {Header} from "./Header";
import {Routes} from "react-router";
import {useProfile} from "../UserProfileProvider";
import {RedeemInvite} from "./RedeemInvite/RedeemInvite";
import {UserProfile} from "./Home/UserProfile";
import ProviderSearchResults from "./Home/MapSearch/ProviderSearchResults";
import {ProviderMain} from "./Home/Provider/ProviderMain";
import {HomePage} from "./Home/HomePage";
import {PrivateAlpha} from "./PrivateAlpha";
import Footer from "./Footer";
import {AiChatPage} from "./Home/AiChatPage";
import {WellnessSurvey} from "./Home/WellnessSurvey/WellnessSurvey";

/**
 * This is the parent of everything on the site. It acts as a login gate
 * so we only allow people in the whitelist. If the user is not logged
 * in they are prompted to log in.
 *
 * If they're not in the whitelist they are just not permitted.
 *
 * If they're in, then the rest of the app renders.
 */
export const App = () => {

  return (
      <div className="App">
        <Body/>
      </div>
  );
}

/**
 * Hack perhaps? Wraps the child with a header and footer. There is ONE child that does
 * not use the standard Header/Footer which is the provider page.
 */
const wrap = (children: ReactElement) => {
  return (<>
    <Header/>
    {children}
    <Footer/>
  </>);
}

/**
 * This is extracted from above so I can do if/else clauses for all the possible
 * options rather than {!profileError && !profileLoading && profile && ..} nonsense.
 */
const Body = () => {

  const {isLoading, user, logout} = useAuth0();
  const {profile, profileError, profileLoading} = useProfile();

  if (isLoading) {
    return wrap(<div className={'AppSection BeigeSection'} style={{height: 250}}>Loading user...</div>);
  }

  if (!user) {
    return wrap(<PrivateAlpha/>);
  }

  // Ok now there is definitely a user...

  if (profileLoading) {
    return wrap(<div className={'AppSection BeigeSection'} style={{height: 250}}>Loading profile...</div>);
  }

  if (profileError) {
    return wrap(<div className={'AppSection BeigeSection'} style={{height: 250, color: 'red'}}>
      Error loading your profile: {profileError}
    </div>);
  }

  // Now there's either a profile or not!
  // If there's not, they aren't on the whitelist BUT they are allowed to redeem an invite.
  if (!profile) {
    return <Routes>
      <Route path="/redeemInvite/:inviteCode" element={wrap(<RedeemInvite/>)}/>
      <Route path="*" element={
        wrap(<div className={'AppSection BeigeSection'}>
          <div style={{
            minHeight: 250, display: 'flex', flexDirection: 'column',
            alignItems: 'center', justifyContent: 'center',
            fontFamily: 'Nunito, sans-serif', fontSize: 20
          }}>
            We're sorry but it appears you don't have early access.
            <br/><br/>
            <button style={{fontFamily: 'Nunito, sans-serif', fontSize: 18}}
                    onClick={() => logout()}>Log out</button>
          </div>
        </div>)}/>
    </Routes>
  }

  // Everything is good in the world. There's a profile.
  return <Routes>
    <Route path="/" element={wrap(<HomePage/>)}/>
    <Route path="/chat" element={wrap(<AiChatPage/>)}/>
    <Route path="/quiz" element={wrap(<WellnessSurvey/>)}/>
    <Route path="/redeemInvite/:inviteCode" element={wrap(<RedeemInvite/>)}/>
    <Route path='/search' element={wrap(<ProviderSearchResults/>)}/>
    <Route path='/profile' element={wrap(<UserProfile/>)}/>
    <Route path='/p/:providerShortUrl/*' element={<ProviderMain/>}/>
    <Route path="*" element={wrap(<div>404 Page Not found</div>)}/>
  </Routes>
}

export default App;
