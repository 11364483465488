import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {SendRpc} from "../../../rpcSender";
import {
  GetProviderProfileRequest,
  GetProviderProfileResponse, IApiBusinessProto,
  IProviderProto,
  IProviderServiceProto
} from "../../../compiled";
import {useAuth0} from "@auth0/auth0-react";
import './Provider.css';
import '../../App.css';
import {Review} from "./Review";
import {BookingOverlay} from "./BookingOverlay/BookingOverlay";
import Markdown from "react-markdown";
import {ProviderBios} from "./ProviderBios";

/**
 * Loads the provider and stores that in an outlet context so subpages can use it.
 *
 * Has its own router because the appointment type is done as a url param, i guess?!
 * @constructor
 */
export const ProviderMain = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [business, setBusiness] = useState<IApiBusinessProto>()

  const {getIdTokenClaims} = useAuth0();

  const {providerShortUrl} = useParams();

  // This is for viewing a draft. The businessId and version ID need
  // to be specified.
  const [searchParams] = useSearchParams()
  const businessId = searchParams.get('b') || '';
  const version = searchParams.get('v') || '';

  const [selectedCategory, setSelectedCategory] = useState<string>()

  // For booking
  const [selectedService, setSelectedService] = useState<IProviderServiceProto | null>();

  useEffect(() => {

    setLoading(true);
    SendRpc(getIdTokenClaims, 'get_business_details',
        GetProviderProfileRequest.encode(new GetProviderProfileRequest({
          shortUrl: providerShortUrl,
          businessId: businessId,
          version: version,
        })).finish())
        .then(responseBytes => {
          let response = GetProviderProfileResponse.decode(responseBytes);
          if (!response.okay || !response.business) {
            setError("Error loading this provider")
            return;
          }
          setBusiness(response.business);
          console.log(response.business);
          if (response.business.practice?.services && response.business.practice.services.length > 0) {
            setSelectedCategory(response.business.practice.services[0].category || '');
          }
        })
        .catch(e => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [])

  if (loading) {
    return <div>loading...</div>
  }

  if (error) {
    return <div>Error loading provider! {error}</div>
  }

  if (!business) {
    return <div>Error loading provider!</div>
  }

  const getUniqueCategories: () => string[] = () => {
    if (!business.practice?.services) {
      return [];
    }

    return business.practice.services.map(service => service.category || '')
        .filter((value, index, array) => array.indexOf(value) === index);
  }

  return (<>

        {(businessId || version) &&
            <div className={'AppSection DraftWarningSection'}>
              <div className={'AppSectionContent DraftWarning'}>
                This is a draft version of your website. Please head to your
                provider dashboard to make changes.
              </div>
            </div>
        }

        <div className={'AppSection'}>
          <div className={'AppSectionContent VerticalFlexBox Flex1'}>

            <div className={'ProviderSectionMargin'}>

              <div className={'ProviderHeaderRow'}>
                <div className={'ProviderHeaderTitle'}>{business.proto?.businessName?.toUpperCase()}</div>
                <div className={'ProviderHeaderLink'}>ABOUT</div>
                <div className={'ProviderHeaderLink'}>SERVICES</div>
                <div className={'ProviderHeaderLink'}>REVIEWS</div>
                <div className={'ProviderHeaderLink ProviderBookNow'}>BOOK NOW</div>
              </div>

              <div className={'ProviderHeaderRow'}>
                <div className={'ProviderHeaderReviews'}>★ 5.0 (<a href={'#'}>20 reviews</a>)</div>
              </div>


              <div className={'ProviderDescriptionRow'}>

                <div className={'ProviderDescriptionLeft'}>

                  <div style={{marginBottom: 40}}><Markdown>{business.proto?.businessDescription}</Markdown></div>

                  <div className={'ProviderBookNow'}>BOOK A FREE VIDEO CONSULTATION</div>
                  <div style={{fontSize: 12, textAlign: 'left', fontStyle: 'italic'}}>
                    Next availability: Fri, Nov 15th at 1pm
                  </div>
                </div>

                <div className={'Flex1'}>
                  <img style={{maxWidth: 500, maxHeight: 465}}
                       src={business.proto?.imageUrl && business.proto.imageUrl[0] || undefined}/>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className={'AppSection WhiteSection'}>
          <div className={'AppSectionContent Flex1'}>
            <div className={'ProviderSectionMargin ServicesSection'}>
              <div className={'ProviderHeaderTitle'}>SERVICES & TREATMENTS</div>

              <div style={{padding: '30px 60px 30px 60px'}}>

                <div className={'ServiceCategoryContainer'}>
                  {getUniqueCategories().map(category => {
                    console.log('category' + category)
                    return <div
                        onClick={() => setSelectedCategory(category)}
                        className={'ServiceCategory' + (selectedCategory == category ? ' ChosenServiceCategory' : '')}>
                      {category.toUpperCase() || 'ALL'}</div>
                  })}
                </div>

                {business.practice?.services?.map(service => {

                  console.log(service)

                  if (selectedCategory && selectedCategory != service.category) {
                    return <></>
                  }

                  return <div className={'Flex1 HorizontalFlexBox ServiceContainer'}>

                    <div className={'Flex1 VerticalFlexBox'} style={{textAlign: 'left', gap: 10}}>
                      <div style={{fontWeight: "bold"}}>
                        {service.name} ({service.lengthMinutes} min)
                        {/*· {service.price == 0 ? 'Free' : '$' + service.price}*/}
                        &nbsp; FREE
                      </div>
                      <div>{service.description}</div>
                    </div>

                    <button className={'BookServiceButton'} onClick={() => {
                      setSelectedService(service);
                    }}>BOOK
                    </button>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>

        <ProviderBios provider={business.providers}/>

        <div className={'AppSection BeigeSection'}>
          <div className={'AppSectionContent HorizontalFlexBox Flex1'} style={{margin: 50}}>

            <div className={'ProviderDetails'}>
              <img className={'ProviderDetailsImage'}
                   src={require('../../../images/provider/education.png')} alt={'a'}/>
              <span className={'ProviderDetailsHeader'}>EDUCATION & CERTIFICATIONS </span>
              <span className={'ProviderDetailsText'}>
                + Doctor of Acupuncture, Pacific College of Oriental Medicine
              </span>
              <span className={'ProviderDetailsText'}>
                + Traditional Chinese Medicine Dermatology Diploma, Avicenna UK
              </span>
            </div>

            <div className={'ProviderDetails'}>
              <img className={'ProviderDetailsImage'}
                   src={require('../../../images/provider/patient_focus.png')} alt={'a'}/>
              <span className={'ProviderDetailsHeader'}>PATIENT FOCUS</span>
              <span className={'ProviderDetailsText'}>
                + Treats all ages
              </span>
              <span className={'ProviderDetailsText'}>     
                + Prenatal
                </span>
              <span className={'ProviderDetailsText'}>              
                + Pediatric
              </span>
            </div>

            <div className={'ProviderDetails'}>
              <img className={'ProviderDetailsImage'}
                   src={require('../../../images/provider/languages.png')} alt={'a'}/>
              <span className={'ProviderDetailsHeader'}>LANGUAGES</span>
              <span className={'ProviderDetailsText'}>
                + English
              </span>
              <span className={'ProviderDetailsText'}>
                + Mandarin Chinese
              </span>
            </div>

          </div>
        </div>

        <div className={'AppSection BlackSection'}>
          <div className={'AppSectionContent HorizontalFlexBox Flex1'} style={{margin: 50}}>

            <div className={'ProviderDetails'}>
              <h3 className={'ProviderDetailsHeader'}>INSURANCE POLICY </h3>
              <span className={'ProviderDetailsText'}>
                We are in-network for these insurance plans. Please note you may first need to meet a minimum 
                deductible under you plan. See Kenko’s Insurance FAQs for more information.
              </span>
            </div>

            <div className={'ProviderDetails'}>
              <h3 className={'ProviderDetailsHeader'}>CANCELLATION POLICY</h3>
              <span className={'ProviderDetailsText'}>
                Please contact us at least 24 hours before your scheduled appointment to avoid a cancellation
                fee. See our <a href={'#'}>cancellation policy</a> for more information.
              </span>
            </div>

          </div>
        </div>

        <div className={'AppSection BeigeSection'}>
          <div className={'AppSectionContent ProviderReviewContainer'}>
            <Review image={require('../../../images/6.png')} name={"Jane Smith"} date={"1 week ago"}
                    text={'My skin has never felt clearer and more balanced since starting TCM treatments focused on natural...See more.'}/>
            <Review image={require('../../../images/7.png')} name={'Mary Kim'} date={'4 months ago'}
                    text={"I saw significant improvement in my skin's texture and tone through personalized herbal remedies...See more."}/>
            <Review image={require('../../../images/9.png')} name={'Elias Bronstein'} date={'6 weeks ago'}
                    text={'This TCM practice transformed my skin, reducing redness and inflammation in a way that...See more.'}/>
            <Review image={require('../../../images/8.png')} name={'Loryane Bilson'} date={'12 months ago'}
                    text={'Thanks to their tailored TCM approach, my chronic skin issues have dramatically improved, and...See more.'}/>
          </div>
        </div>

        <div className={'AppSection BlackSection'}>
          <div className={'AppSectionContent Flex1'}>

            <div style={{flex: 1, display: 'flex', flexDirection: 'row', margin: 50}}>

              <div style={{flex: '1', display: 'flex', overflow: 'hidden', justifyContent: 'center'}}>
                <img src={require('../../../images/map_ny.png')}
                     style={{maxHeight: 600, alignSelf: 'center'}}
                     alt={'map'}/>
              </div>

              <div className='VerticalFlexBox Flex1 MapRight'>
                <span>114 6th Ave</span>
                <span>New York, NY 10036</span>

                <h3>BUSINESS HOURS</h3>
                <span>Mondays 1-3pm</span>
                <span>Tuesdays 1-3pm</span>
                <span>Fridays 12-5pm</span>

                <h3>ADDITIONAL INFORMATION</h3>
                <span>Instant booking confirmation</span>
              </div>
            </div>
          </div>
        </div>

        {selectedService && <BookingOverlay
            provider={business}
            service={selectedService}
            onCancel={() => setSelectedService(null)}/>}
      </>
  );
}
