import {IdToken} from "@auth0/auth0-spa-js";
import React from "react";
import {ChatMessage} from "../App/Home/AiChatPage";


export const askLlama = (
    tokenFetcher: () => Promise<IdToken | undefined>,
    messages: ChatMessage[]): Promise<Response> => {


  // Hack. Use environment settings
  const llamaEndpoint = window.location.host.startsWith("2localhost") ?
      'http://localhost:8081/llama' :
      'https://booking-1096702705153.us-central1.run.app/llama'

  return new Promise((resolve, reject) => {

    // TODO-- the user could probably mess with system messages
    // if this endpoint was exposed.
    const body = JSON.stringify(messages.map(message => {
      return {
        role: message.outgoing ? "user" : "assistant",
        content: message.message
      };
    }));
    
    tokenFetcher().then(token => {
      fetch(llamaEndpoint, {
        headers: {
          'Authentication': token?.__raw || '',
        },
        method: 'POST',
        // The model and system prompts are added by the server.
        // We don't want the client to be able to do this.
        body: body
        
      }).then(value => {
        if (value.ok) {
          resolve(value);
        } else {
          reject('Status code ' + value.status)
        }
      }).catch((e) => {
        reject(e);
      })
    })
        .catch(e => {
          console.error(e)
          reject('Failed to get auth token');
        })
  });
}