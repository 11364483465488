import './Provider.css';

type Props = {
  name: string,
  date: string,
  text: string,
  image: string,
}

export const Review = (props: Props) => {

  return <div className={'ProviderReview'}>

    <div className={'HorizontalFlexBox'} style={{alignItems: 'center', gap: 5}}>
      <img className={'ReviewerImage'} src={props.image}/>
      <div className={'VerticalFlexBox'}>
        <span>{props.name}</span>
        <span>{props.date}</span>
      </div>
    </div>

    <span style={{marginTop: 5, marginBottom: 5}}>★★★★★</span>
    <span>{props.text}</span>

  </div>

}