import './App.css'
import React from "react";
import {GetNetworkConfigurationResponseProto} from "../compiled";
import {useAuth0} from '@auth0/auth0-react';
import {useProfile} from "../UserProfileProvider";
import {Link} from "react-router-dom";

interface Props {
}

export const Header = (props: Props) => {

  const {user, logout, loginWithRedirect} = useAuth0();
  const {profile} = useProfile();

  const host = window.location.host

  const auth0Login = () => {
    return loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  const auth0Logout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  return (
      <div className={'AppSection BeigeSection'}>
        <div className={'AppSectionContent Flex1'}>

          <div className={'Header'}>
            <div className={'HeaderLeftElement'}>
              <Link style={{display: 'flex', justifyContent: 'left'}} to={'/'}>
                <img alt={'Kenko Logo'} src={require('../images/logo.png')}
                     className={'HeaderLogo'}/>
              </Link>
              {host.startsWith("localhost") && <span style={{color: 'blue'}}>local&nbsp;</span>}
              {host.endsWith("thymebook.com") && <span style={{color: 'green'}}>dev&nbsp;</span>}
            </div>

            {/* the header is greatly simplified once a user logs in */}
            {!user &&
            <div className={'HeaderRightElement'}>
              <a className={'StandardButton'} href={'https://provider.thymebook.com'}>BECOME A PRACTITIONER</a>
            </div>}

            <div className={'HeaderRightElement'}>
              {!user && <button className={'StandardButton'} onClick={auth0Login}>LOGIN</button>}
          
              {user && <Link to={'/profile'}>
                <img className={'ProfileButton'} src={require('../images/icons/profile.png')}/>
              </Link>}
            </div>

            {!user && <div className={'HeaderRightElement'}>
              <button className={'CalloutButton'} onClick={() => {}}>FREE WELLNESS ASSESSMENT</button>
            </div>}
            
          </div>
        </div>
      </div>
  );
} 