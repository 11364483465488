import React, {useState} from "react";
import {CategoryIcon} from "./CategoryIcon";
import {useNavigate} from "react-router-dom";
import {SuggestedPromptsOverlay} from "./SuggestedPromptsOverlay";
import {WhatIsKenkoSection} from "./Sections/WhatIsKenkoSection";
import {ArticlePreviewSection} from "./Sections/ArticlePreviewSection";

export const HomePage = () => {

  const navigate = useNavigate();

  const [showOverlay, setShowOverlay] = useState(false);
  const [textInput, setTextInput] = useState('');

  const ny = {
    n: 40.8,
    s: 40.7,
    e: -73.9,
    w: -74.1,
  }

  const city = ny;

  const prompts = [
    {index: 0, value: 'I want to find an acupuncturist near me.'},
    {index: 1, value: 'I want to learn more about Eastern wellness treatments for my health condition.'},
    {index: 2, value: 'I want to take a free wellness assessment'},
  ]

  const handlePromptSelection = (i: number) => {
    if (i == 0) {
      navigate(`/search?s=${city.s}&e=${city.e}&w=${city.w}&n=${city.n}`);
    }
    if (i == 1) {
      navigate('/chat', {
        state: {
          message: prompts[1].value
        }
      });
    }
    if (i == 2) {
      navigate('/quiz');
    }
  }

  return (<>
        <div className={'AppSection BeigeSection'}>
          <div className={'AppSectionContent WelcomeSection'}>

            <h1 style={{maxWidth: 600}}>
              Transform Your Future Health.
            </h1>

            <h2 style={{maxWidth: 580}}>
              Expert-led, integrative care that meets you where you’re at today, for a healthier tomorrow.
            </h2>

            <input type={'text'}
                   inputMode={'search'}
                   className={'TextInputStyle'}
                   placeholder={"What can we help you with today?"}
                   value={textInput}
                   onChange={e => {
                     console.log('yup', e.currentTarget.value)
                     setTextInput(e.currentTarget.value);
                   }}
                   onKeyPress={e => {
                     if (e.key == 'Enter' || e.key == 'Return') {
                       navigate('/chat', {
                         state: {
                           message: e.currentTarget.value
                         }
                       });
                     }
                   }}
                   onBlur={e => {
                     setShowOverlay(false);
                   }}
                   onFocus={e => {
                     setShowOverlay(true);
                   }}
            />

            {/* Width is the same as the text input */}
            <div className={'OverlayPositioner'}>
              {showOverlay && <SuggestedPromptsOverlay
                  prompts={prompts.filter(prompt => {
                    return prompt.value.toLowerCase().startsWith(textInput.toLowerCase());
                  })}
                  callback={handlePromptSelection}/>}
            </div>
          </div>
        </div>

        <WhatIsKenkoSection/>

        <div className={'AppSection DarkSection'}>
          <div className={'AppSectionContent'}>

            <div className={'CategoriesSection'}>
              <CategoryIcon category={'Acupuncture'} icon={require('../../images/icons/acupuncture.png')}/>
              <CategoryIcon category={'TCM'} icon={require('../../images/icons/tcm.png')}/>
              <CategoryIcon category={'Homepathy'} icon={require('../../images/icons/homeopathy.png')}/>
              <CategoryIcon category={'Reiki'} icon={require('../../images/icons/reiki.png')}/>
              <CategoryIcon category={'Meditation'} icon={require('../../images/icons/meditation.png')}/>
              <CategoryIcon category={'Naturopathy'} icon={require('../../images/icons/naturopathy.png')}/>
              <CategoryIcon category={'Ayurveda'} icon={require('../../images/icons/ayurveda.png')}/>
              <CategoryIcon category={'Pelvic Health'} icon={require('../../images/icons/pelvic_health.png')}/>
            </div>
          </div>
        </div>

        <div className={'AppSection WhiteSection'}>
          <div className={'AppSectionContent FoundersSection'}>

            <div className={'FoundersSectionLeft'}>
              <img src={require('../../images/home_founders.jpeg')}
                   className={'FoundersImage'}
                   alt={'our founders'}/>
            </div>

            <div className={'FoundersSectionRight'}>
              <h2>From our healing journeys to yours.</h2>
              <p>
                After facing our own health challenges, ranging from infertility and miscarriage to chronic back
                pain and eczema, we saw the limitations of conventional care. We found healing through Eastern and
                Western holistic care, but the process of getting there was not easy. We built Kenko as a
                resource for people like us, who deserve quality care that lets you be your healthiest self for
                years to come.
              </p>
              <div className={'FoundersJoinUs'}>
                JOIN US
              </div>
            </div>
          </div>
        </div>

        <ArticlePreviewSection/>
      </>
  )
}