import React from "react";

interface Props {
  callback: (position: number) => void;
  prompts: {index: number, value: string}[];
}

export const SuggestedPromptsOverlay = (props: Props) => {

  if (props.prompts.length == 0) {
    return <></>;
  }
  
  return <div className={'Overlay'}>
    {props.prompts.map(prompt => {
      return <div key={'prompt-' + prompt.index} className={'OverlayElement'}
                  onMouseDown={() => {
                    props.callback(prompt.index);
                  }}
      >{prompt.value}</div>
    })}
  </div>
}