import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import './App.css'

export const PrivateAlpha = () => {

  const {loginWithRedirect} = useAuth0();

  const auth0Login = () => {
    return loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  return <>
    <div className={'AppSection BeigeSection'}>
      <div className={'AppSectionContent WelcomeSection'}>

        <h1 style={{maxWidth: 600}}>
          Transform Your Future Health.
        </h1>

        <h2 style={{maxWidth: 580}}>
          Expert-led, integrative care that meets you where you’re at today, for a healthier tomorrow.
        </h2>
      </div>
    </div>

    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent'}>

        <div style={{margin: 60}}>
          Kenko is currently in private Alpha. Please log in to access Kenko.
        </div>
      </div>
    </div>
  </>
}