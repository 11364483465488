import {Link, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
  AcceptInvitationRequest,
  AcceptInvitationResponse,
  CheckInvitationRequest,
  CheckInvitationResponse,
} from '../../compiled';
import {SendRpc} from '../../rpcSender';
import {useAuth0} from '@auth0/auth0-react';
import {useProfile} from "../../UserProfileProvider";

export const RedeemInvite = () => {

  const {inviteCode} = useParams()

  const {user} = useAuth0();
  const {getIdTokenClaims} = useAuth0();
  const {profile, setProfile} = useProfile();

  // if the rpc is out
  const [checkingInvite, setCheckingInvite] = useState(true);
  const [checkInviteError, setCheckInviteError] = useState('');
  const [checkInviteResponse, setCheckInviteResponse] = useState<CheckInvitationResponse | null>();

  const [redeemError, setRedeemError] = useState(false);

  useEffect(() => {

    setCheckInviteResponse(null);

    if (!inviteCode) {
      setCheckingInvite(false);
      return;
    }

    setCheckingInvite(true)

    let request = CheckInvitationRequest.encode(new CheckInvitationRequest({
      inviteCode: inviteCode,
    })).finish();

    SendRpc(getIdTokenClaims, 'action/check_invite', request).then(r => {
      let response = CheckInvitationResponse.decode(r);
      setCheckInviteResponse(response);
    }).catch(e => {
      console.log(e);
      setCheckInviteError('Error checking your invite. Please try again later')
    }).finally(() => {
      setCheckingInvite(false);
    });
  }, [inviteCode]);


  const handleAccept = () => {

    setRedeemError(false);

    let request = new AcceptInvitationRequest({
      inviteCode: inviteCode,
      accepted: true,
    });

    let proto = AcceptInvitationRequest.encode(request).finish();

    SendRpc(getIdTokenClaims, 'action/accept_invite', proto).then(r => {
      let response = AcceptInvitationResponse.decode(r);
      if (response.okay) {
        // This changes the context value which forces a rerender
        setProfile(response.profile);
      }
    }).catch(e => {
      console.log(e);
      setRedeemError(true);
    });
  }

  // Note this branch happens as soon as there is a profile, aka as soon
  // as the redeem invite succeeds. Any special UI transition should be here.
  if (profile) {
    return <div>Welcome to the Kenko Community, {user?.nickname}!
      We're so glad you've joined us.
      <br/><br/>
      Try navigating to <Link to={'/'}>our home page</Link> now!
    </div> 
  }

  if (checkingInvite) {
    return <div>Looking up your invite...</div>
  }

  if (checkInviteError || !checkInviteResponse?.okay) {
    return <div>error checking invite code. it may be invalid or expired</div>
  }

  return <div>
    you have been invited to the kenko community
    <br/><br/>
    would you like to join as {user?.email}?&nbsp;
    <button onClick={handleAccept}>yes!</button>
    {redeemError && <div style={{color: 'red'}}>Error redeeming, sorry!</div>}
  </div>
}
