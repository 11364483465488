import './App.css';
import {Link} from 'react-router-dom';
import {SocialLinks} from './SocialLinks';

const Footer = () => {
  return (
      <div className={'AppSection BlackSection'}>
        <div className={'AppSectionContent Footer'}>

          <div className={'FooterTopRow'}>
            <Link to={'/'}>
              <img src={require('../images/logo.png')}
                   alt={'Kenko logo'}
                   className={'FooterLogo'}/>
            </Link>
            <SocialLinks lightBackground={false}/>
          </div>

          <div className={'FooterMiddleRow'}>

            <div className='FooterRightColumn'>
              <div><b>COMPANY</b></div>
              <div>About Us</div>
              <div>Careers</div>
              <div>Press</div>
            </div>

            <div className='FooterRightColumn'>
              <div><b>RESOURCES</b></div>
              <div>Library</div>
              <div><a href={'https://open.spotify.com/show/56VTuF3zOsNDYE8oC2IKaE?si=62aa4d156ed54742'}
                      target={'_blank'}>Podcast</a></div>
            </div>

            <div className='FooterRightColumn'>
              <div><b>LEGAL</b></div>
              <div>Terms of Service</div>
              <div><Link to={'/privacy'}>Privacy Policy</Link></div>
              <div>Cookie Policy</div>
            </div>

          </div>

          <div className={'FooterBottomRow'}>
            © KENKO SPACE, LLC 2024
            <br/>
            ALL RIGHTS RESERVED.
          </div>


        </div>
      </div>
  );
};

export default Footer;