/**
 * If there's only a single practitioner, this larger bio is shown.
 */
import {space} from "../../../compiled";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;
import React from "react";
import './Provider.css';

type Props = {
  provider: IProviderBioProto[] | null | undefined;
}

/**
 * Returns a single large bio if there's only one practitioner. Returns
 * a carousel if there are more.
 * @param props
 * @constructor
 */
export const ProviderBios = (props: Props) => {

  // TODO - filter by practitioners not providers.
  const practitioners = props.provider;

  if (!practitioners?.length) {
    return <></>
  }

  if (practitioners.length > 1) {
    return <SmallBios providers={practitioners}/>
  }

  return <LargeBio provider={practitioners[0]}/>;

}

type LargeBioProps = {
  provider: IProviderBioProto;
}

const LargeBio = (props: LargeBioProps) => {

  const provider = props.provider;

  return <div className={'AppSection RedSection'}>
    <div className={'AppSectionContent'}>

      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>

        <div style={{flex: '1', display: 'flex', overflow: 'hidden', justifyContent: 'center'}}>
          <img src={provider.imageUrls?.[0]}
               style={{maxHeight: 768, alignSelf: 'center'}}
               alt={'our founders'}/>
        </div>

        <div className='Flex1'>
          <div className={'ProviderSectionMargin ProviderBioSection'}>
            <h2>Hello from {provider.firstName} {provider.lastName}</h2>
            <p>
              {provider.bio}
            </p>
            <div className={'ServiceCategory'}>
              Book an Appointment
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}


type SmallBiosProps = {
  providers: IProviderBioProto[];
}

const SmallBios = (props: SmallBiosProps) => {


  return <div className={'AppSection WhiteSection'}>
    <div className={'AppSectionContent Flex1'}>
      <div className={'ProviderSectionMargin'}>

        <div className={'ProviderHeaderRow'}>

          <div className={'ProviderHeaderTitle'}>OUR TEAM</div>
        </div>
        <div className={'SmallBiosCarousel'}>
          {props.providers.map(provider => {
            return <SmallBio provider={provider} key={provider.providerId}/>
          })}
        </div>
      </div>
    </div>
  </div>
}

type SmallBioProps = {
  provider: IProviderBioProto;
}

const SmallBio = (props: SmallBioProps) => {

  const provider = props.provider;
  return <div className={'SmallBio'}>
    <div className={'SmallBioImageMask'}>
      <img className={'SmallBioImage'} src={provider.imageUrls?.[0]}/>
    </div>
    <div className={'SmallBioName'}>{provider.firstName} {provider.lastName}</div>
    <div className={'SmallBioBio'}>{provider.bio}</div>
    <button className={'SmallBioBookButton'}>Book with {provider.firstName}</button>
  </div>
}

