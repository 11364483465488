import {Link} from "react-router-dom";

interface Props {
  category: string
  icon: any
}

export const CategoryIcon = (props: Props) => {

  const charlotte = {
    n: 35.3,
    s: 35.15,
    e: -80.75,
    w: -80.9,
  }
  
  const ny = {
    n: 40.8,
    s: 40.7,
    e: -73.9,
    w: -74.1,
  }
  
  const city = ny;
  
  return <Link to={`/search?s=${city.s}&e=${city.e}&w=${city.w}&n=${city.n}`}>
    <div className={'CategoryIcon'}>

      <img style={{width: 100, height: 100}} src={props.icon} alt={props.category}/>
      <span>{props.category}</span>
    </div>
  </Link>

}